import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'devextreme/dist/css/dx.light.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Survey = lazy(() => import("./pages/qst"));
const NoFound = lazy(() => import("./pages/no-found"));
const Unauthorized = lazy(() => import("./pages/unauthorized"));
const Completed = lazy(() => import("./pages/completed"));

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<App />} />
        <Route exact path='/survey/:id' element={<Survey />} />
        <Route exact path='/unauthorized' element={<Unauthorized />} />
        <Route exact path='/completed' element={<Completed />} />
        <Route path='*' element={<NoFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);