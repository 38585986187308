import './App.css';

function App() {
  return (
    <div className="App">
      <div>
        <img src='/images/home.png' alt='' />
        <div>Welcome</div>
      </div>
    </div>
  );
}

export default App;
